<template>
  <div id="app" :class="{ fadeOut: refreshing }">
    <!-- <NavbarComponent /> -->
    <router-view />
    <loading-screen-component />

    <footer-component v-if="isFooterVisible" />
    <popup-component v-if="isPopuperVisible" />
  </div>
</template>

<script>
import FooterComponent from "./components/FooterComponent.vue";
import LoadingScreenComponent from "./components/LoadingScreenComponent.vue";
import PopupComponent from "./components/PopupComponent.vue";
// import NavbarComponent from "./components/NavbarComponent.vue";

export default {
  components: { FooterComponent, PopupComponent, LoadingScreenComponent },
  name: "App",
  data() {
    return {
      refreshing: false,
      isFooterVisible: true,
      isPopuperVisible: true,
      // loading: false,
    };
  },
  mounted() {
    window.addEventListener("beforeunload", () => {
      this.refreshing = true;
    });
    // this.$root.$on("ajax-start", () => {
    //   this.loading = true;
    // });
    // this.$root.$on("ajax-stop", () => {
    //   this.loading = false;
    // });
  },
  created() {
    this.hideComponentsIfNeeded();
  },
  methods: {
    hideComponentsIfNeeded() {
      const currentRouteName = this.$route.name;
      if (currentRouteName === "NotFound" || currentRouteName === "Invoice") {
        this.isFooterVisible = false;
        this.isPopuperVisible = false;
      }
    },
  },
};
</script>

<style>
.fadeOut {
  animation: 0.5s ease;
}
</style>
<head>
  <title>{{ $metaInfo.title }}</title>
</head>
