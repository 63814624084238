<template>
  <div>
    <navbar-component />
    <section class="terms">
      <div class="container">
        <div class="header-terms">
          <h1>Syarat & Ketentuan</h1>
        </div>
        <div class="subhead">
          <p>Selamat datang di {{ web }}</p>
          <p>
            Syarat & ketentuan yang ditetapkan di bawah ini mengatur pemakaian
            jasa yang ditawarkan oleh {{ store }} terkait penggunaan situs
            {{ web }}. Pengguna disarankan membaca dengan seksama karena dapat
            berdampak kepada hak dan kewajiban Pengguna di bawah hukum.
          </p>
          <p>
            Dengan mendaftar dan/atau menggunakan situs {{ web }}, maka pengguna
            dianggap telah membaca, mengerti, memahami dan menyetujui semua isi
            dalam Syarat & ketentuan. Syarat & ketentuan ini merupakan bentuk
            kesepakatan yang dituangkan dalam sebuah perjanjian yang sah antara
            Pengguna dengan {{ store }}. Jika pengguna tidak menyetujui salah
            satu, pesebagian, atau seluruh isi Syarat & ketentuan, maka pengguna
            tidak diperkenankan menggunakan layanan di {{ web }}.
          </p>
        </div>
        <div class="content">
          <div class="header-content">
            <h2>A. Persyaratan Usia</h2>
          </div>
          <div class="list">
            <ol>
              <li>
                Untuk mendaftar sebagai pengguna dan melakukan transaksi, Anda
                harus berusia minimal 18 tahun atau sesuai dengan batas usia
                yang ditetapkan oleh hukum negara tempat Anda tinggal.
              </li>
              <li>
                Jika Anda di bawah usia yang diizinkan, Anda harus mendapatkan
                izin dari orang tua atau wali hukum sebelum mendaftar dan
                menggunakan situs ini.
              </li>
            </ol>
          </div>
          <div class="header-content">
            <h2>B. Informasi Pendaftaran</h2>
          </div>
          <div class="list">
            <ol>
              <li>
                Saat mendaftar, Anda harus menyediakan informasi pribadi yang
                akurat, lengkap, dan terkini.
              </li>
              <li>
                Anda bertanggung jawab untuk memperbarui informasi pribadi Anda
                jika terjadi perubahan.
              </li>
              <li>
                {{ store }} tidak memungut biaya pendaftaran kepada Pengguna.
              </li>
            </ol>
          </div>
          <div class="header-content">
            <h2>C. Akun Pengguna</h2>
          </div>
          <div class="list">
            <ol>
              <li>
                Pengguna memahami bahwa 1 (satu) email hanya dapat digunakan
                untuk mendaftar 1 (satu) akun Pengguna {{ store }}
              </li>
              <li>
                Pengguna setuju untuk memastikan bahwa Pengguna keluar dari akun
                di akhir setiap sesi dan memberitahu {{ store }} jika ada
                penggunaan tanpa izin atas sandi atau akun Pengguna.
              </li>
              <li>
                Anda bertanggung jawab untuk menjaga kerahasiaan informasi login
                Anda dan tidak memberikan akses ke akun Anda kepada pihak lain.
              </li>
            </ol>
          </div>
          <div class="header-content">
            <h2>D. Tanggung Jawab Pengguna</h2>
          </div>
          <div class="list">
            <ol>
              <li>
                Anda bertanggung jawab atas segala aktivitas yang terjadi dalam
                akun Anda.
              </li>
              <li>
                Anda harus memberikan informasi yang benar dan akurat saat
                melakukan transaksi dan menghindari tindakan yang melanggar
                hukum atau merugikan pihak lain.
              </li>
            </ol>
          </div>
          <div class="header-content">
            <h2>E. Penyalahgunaan Akun</h2>
          </div>
          <div class="list">
            <ol>
              <li>
                Jika Anda mencurigai adanya penggunaan yang tidak sah atau
                penyalahgunaan akun Anda, segera hubungi tim dukungan kami.
              </li>
              <li>
                Kami berhak untuk menangguhkan atau menghapus akun pengguna jika
                terdapat bukti pelanggaran syarat dan ketentuan atau kegiatan
                yang merugikan.
              </li>
            </ol>
          </div>
          <div class="header-content">
            <h2>F. Penonaktifan Akun</h2>
          </div>
          <div class="list">
            <ol>
              <li>
                Jika Anda ingin menonaktifkan atau menghapus akun pengguna Anda,
                harap hubungi tim dukungan kami.
              </li>
              <li>
                Kami berhak menonaktifkan atau menghapus akun pengguna jika
                tidak mematuhi syarat dan ketentuan yang berlaku.
              </li>
            </ol>
          </div>
          <div class="header-content">
            <h2>G. Pemesanan, Pembayaran dan Pengiriman</h2>
          </div>
          <div class="list">
            <ol>
              <li>
                Dengan menempatkan pesanan, Anda menyetujui untuk membeli produk
                atau jasa sesuai dengan syarat dan ketentuan yang ditetapkan.
              </li>
              <li>
                Anda bertanggung jawab untuk memberikan informasi pengiriman
                yang akurat dan lengkap.
              </li>
              <li>
                Pembayaran harus dilakukan sesuai dengan metode pembayaran yang
                tercantum di situs.
              </li>
              <li>
                Kami akan melakukan upaya yang wajar untuk mengirimkan produk
                sesuai dengan List antrian.
              </li>
              <li>
                Jika ada perubahan atau keterlambatan dalam pengiriman, kami
                akan memberikan pemberitahuan kepada Anda.
              </li>
            </ol>
          </div>
          <div class="header-content">
            <h2>H. Pembatasan Tanggung Jawab</h2>
          </div>
          <div class="list">
            <ol>
              <li>
                Kami tidak bertanggung jawab atas kerugian langsung, tidak
                langsung, insidental, atau konsekuensial yang timbul dari
                penggunaan situs ini atau produk yang dibeli.
              </li>
              <li>
                Kami tidak bertanggung jawab atas kesalahan atau kelalaian yang
                dilakukan oleh pihak ketiga yang terkait dengan transaksi atau
                layanan yang disediakan.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
export default {
  components: { NavbarComponent },
  metaInfo() {
    return {
      title: "Syarat & Ketentuan",
    };
  },
  data() {
    return {
      web: "www.shop.sfisolutions.id",
      store: "SFI SOLUTIONS",
    };
  },
};
</script>
<style>
@import url("https://fonts.cdnfonts.com/css/open-sauce-one");
.terms {
  margin-top: 50px;
  margin-bottom: 50px;
  color: #212121;
  font-family: "Open Sauce One", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
@media (min-width: 1400px) {
  .terms .container {
    max-width: 920px;
  }
}
.list li,
.subhead p {
  font-size: 14px;
  text-align: justify;
  line-height: 1.5;
}
.header-terms h1 {
  font-size: 2.71429rem;
  margin-bottom: 30px;
}

.header-content h2 {
  font-size: 1.51429rem;
  margin: 36px 0px 10px;
}
ol > ol {
  list-style: lower-alpha;
}

.s-info {
  font-weight: 500;
  font-style: italic;
}
</style>